<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Download Report</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid">
                <v-row no-gutters class="mt-4 mb-n2">
                    <v-col cols="12">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    class="py-0 my-0"
                                    v-model="dateRangeText"
                                    color="primary"
                                    label="Date range"
                                    readonly
                                    flat
                                    solo
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-combobox>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    :loading="loading"
                                    @click="menu = false"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                color="primary"
                :loading="loading"
                :disabled="!valid"
                class="mb-5 mt-n2"
                @click="download"
            >
                DOWNLOAD
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'Report',
    data: () => ({
        loading: false,
        valid: false,
        menu: false,
        dates: [],
        purchases: [],
        users: [],
    }),
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        createXLSX() {
            try {
                const data = []
                for (const purchase of this.purchases) {
                    for (const item of purchase.items) {
                        const row = {}
                        const selectedQuote = item.quotes
                            ? item.quotes.find(quote => quote.able)
                            : undefined
                        row.Project = purchase.project.name
                        row.Description = item.description
                        row.Qty = item.quantity
                        row.Vendor = selectedQuote
                            ? selectedQuote.supplier.name
                            : ''
                        row.PurchaseDate = item.purchaseOrder
                            ? moment
                                  .unix(item.purchaseOrder.dueDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.PO = item.purchaseOrder
                            ? item.purchaseOrder.orderNumber
                            : ''
                        row.Price = selectedQuote ? selectedQuote.price : ''
                        row.Total = selectedQuote
                            ? selectedQuote.price * item.quantity
                            : ''
                        row.Days = selectedQuote ? selectedQuote.days : ''
                        row.DeliveryDate = purchase.inTransitDate
                            ? moment
                                  .unix(purchase.inTransitDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.ReceivedDate = purchase.receivedDate
                            ? moment
                                  .unix(purchase.receivedDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        if (selectedQuote) {
                            row.VendorSelectedDate = new Date(
                                moment
                                    .unix(selectedQuote.createdOn._seconds)
                                    .format('YYYY-MM-DD')
                            )
                            row.VendorSelectedDate.setDate(
                                row.VendorSelectedDate.getDate() +
                                    selectedQuote.days
                            )
                            row.VendorSelectedDate = row.VendorSelectedDate.toISOString().substring(
                                0,
                                10
                            )
                        } else {
                            row.VendorSelectedDate = ''
                        }
                        row.CancelDate = purchase.rejectedDate
                            ? moment
                                  .unix(purchase.rejectedDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.ClosedDate = purchase.closedDate
                            ? moment
                                  .unix(purchase.closedDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.PMDate = purchase.approvedDate
                            ? moment
                                  .unix(purchase.approvedDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.CEODate = purchase.purchasedDate
                            ? moment
                                  .unix(purchase.purchasedDate._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.QuoteDate = selectedQuote
                            ? moment
                                  .unix(selectedQuote.createdOn._seconds)
                                  .format('YYYY-MM-DD')
                            : ''
                        row.Status = purchase.status
                        row.ApprovedBy = purchase.purchasedBy
                            ? this.users.find(
                                  user => user.id == purchase.purchasedBy
                              )?.name
                            : ''
                        data.push(row)
                    }
                }
                // only array possible
                var costsWS = XLSX.utils.json_to_sheet(data)

                // A workbook is the name given to an Excel file
                var wb = XLSX.utils.book_new() // make Workbook of Excel

                // add Worksheet to Workbook
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, costsWS, 'report') // sheetAName is name of Worksheet

                // export Excel file
                XLSX.writeFile(wb, `purchasesReport.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async download() {
            try {
                this.loading = true
                let start = ''
                let end = ''
                if (this.dates[0] < this.dates[1]) {
                    start = this.dates[0]
                    end = this.dates[1]
                } else {
                    start = this.dates[1]
                    end = this.dates[0]
                }
                this.purchases = await API.getPurchases({
                    startDate: start,
                    endDate: end,
                })
                this.createXLSX()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
